<template>
  <div class="task-item-wapper">
    <div @click="tabItem"><img :src="require('@/assets/images/task-box.png')" :class="['task-box-icon', data.is_receive==1 || !progress?'task-box-icon-dis':'']"></div>
    <div class="task-item-content" @click="tabItem" v-loading="loading">
      <img class="bi-icon" :src="require('@/assets/images/vnd.png')" alt="" v-if="data.bl_or_amount>0">
      <img class="bi-icon" style="transform: scale(.9);" :src="require('@/assets/images/integral.png')" v-else>
      <span class="item-num">{{ data.bl_or_amount>0?data.bl_or_amount:data.integral_amount }}</span>
      <div class="tip" v-if="data.is_receive==1">{{ $t("已领取") }}</div>
    </div>
    <div class="progress">
      <div class="progress-bg" :style="`width:${progress}%`"></div>
      <div :class="['progress-content',progress>=50?'progress-content-ac':'']"></div>
    </div>
    <div class="bottom-num">{{ data.max_activity }}</div>
  </div>
</template>

<script>
export default {
  props:{
    progress:{
      type:Number,
      default:0
    },
    data:{
      type:Object
    },
    loading:{
      type:Boolean
    }
  },
  methods:{
    tabItem(){
      if(this.progress && this.data.is_receive!=1){
        this.$emit("tabItem")
      }
    }
  }
}
</script>

<style scoped lang="less">
.tip{
  position: absolute;
  transform: rotate(20deg);
  color: #FFF;
  text-shadow: 0 0 10px #000;
  font-weight: bold;
  font-size: 20px;
  top: 0;
  z-index: 3;
  opacity: .6;
}
.task-item-wapper{
  cursor: pointer;
  width: 100%;
}
.task-box-icon{
  width: 60%;
  transform: translateX(30%);
  position: relative;
  z-index: 2;
}
.task-box-icon-dis{
  filter:  grayscale(100%);
}
.bi-icon{
  width: 28px;
  height: 28px;
  object-fit: cover;
  margin: 8px 4px 0;
}
.task-item-content{
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient( 180deg, #26CD83 0%, #009D42 100%);
  color: #FFDE89;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
  margin-top: -20%;
  padding-bottom: 12px;
  position: relative;
  &::after{
    content: "";
    display: block;
    position: absolute;
    border-top: 10px solid #009D42;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 100%;
  }
}
.item-num{
  font-size: 18px;
}
.progress{
  height: 3px;
  background-color: #3D7C69;
  margin: 30px -8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width:768px) {
    margin: 20px -8px 20px ;
  }
}
.progress-content{
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #3D7C69;
}
.progress-content-ac{
  background-color: #FFD700;
  box-shadow: 0 0 4px #FFD700;
}
.progress-bg{
  position: absolute;
  background-color: #FFD700;
  box-shadow: 0 0 4px #FFD700;
  width: 50%;
  height: 100%;
  left: 0;
}
.bottom-num{
  text-align: center;
  font-size: 21px;
  color: #FFDE89;
  @media (max-width:768px) {
    font-size: 16px;
  }
}
.task-item-wapper .el-loading-mask{
  top: -60%;
  border-radius: 10px;
}

</style>