<template>
  <el-dialog
  :visible.sync="dialogVisible"
  custom-class="custom-dialog el-dialog-center2" :modal-append-to-body="false">
  <div class="wapper">
    <div class="title">{{ data.title }}</div>
    <table>
      <tr>
        <td colspan="1" style="border-right: 0;">{{ $t("描述") }}</td>
        <td colspan="3" style="border-right: 0;">{{ data.introduce }}</td>
      </tr>
      <tr>
        <td rowspan="2">{{ $t("任务条件") }}</td>
        <td v-if="data.task_condition.includes(0)">{{ $t("最低下注") }}</td>
        <td v-if="data.task_condition.includes(1)">{{ $t("最低充值") }}</td>
        <td v-if="data.task_condition.includes(2)">{{ $t("获胜倍率") }}</td>
        <td v-if="data.task_condition.includes(3)">{{ $t("累计充值") }}</td>
        <td v-if="data.task_condition.includes(4)">{{ $t("在线时长") }}</td>
        <td v-if="data.task_condition.includes(5)">{{ $t("累计下注") }}</td>
        <td v-if="data.task_condition.includes(6)">{{ $t("游戏时长") }}</td>
      </tr>
      <tr>
        <!-- <td></td> -->
        <td v-if="data.task_condition.includes(0)">{{ current_symbol }}{{ data.min_bet }}</td>
        <td v-if="data.task_condition.includes(1)">{{ current_symbol }}{{ data.min_recharge }}</td>
        <td v-if="data.task_condition.includes(2)">≥{{ data.multiple }}X</td>
        <td v-if="data.task_condition.includes(3)">≥{{ current_symbol }}{{ data.cumulative_recharge }}</td>
        <td v-if="data.task_condition.includes(4)">≥{{ data.online_time }}{{ $t("分钟") }}</td>
        <td v-if="data.task_condition.includes(5)">≥{{ current_symbol }}{{ data.cumulative_bet }}</td>
        <td v-if="data.task_condition.includes(6)">≥{{ data.game_online_time }}{{ $t("分钟") }}</td>
      </tr>
      <tr>
        <td>{{ $t("进度") }}</td>
        <td colspan="3">{{ data.finish_num }}/{{ data.task_num }}</td>
      </tr>
      <tr>
        <td>{{ $t("奖励") }}</td>
        <td colspan="3">{{ data.reward_type==1?$t('积分'):current_symbol }} {{ data.amount_or_integral }}</td>
      </tr>
      <tr v-if="data.reward_type==0">
        <td>{{ $t("流水倍数") }}</td>
        <td colspan="3">{{ data.savings }}X</td>
      </tr>
      <tr v-if="data.activity">
        <td>{{ $t("任务活跃值") }}</td>
        <td colspan="3">{{ data.activity }}</td>
      </tr>
    </table>
    <el-button class="button_gre btn" @click="dialogVisible = false">{{ $t("关闭") }}</el-button>
  </div>
</el-dialog>
</template>

<script>
export default {
  data(){
    return {
      dialogVisible:false,
      data:{
        task_condition:""
      }
    }
  },
  props:{
    current_symbol:{
      default:""
    }
  },
  methods:{
    show(data){
      this.data = data
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="less">
.wapper{
  color: #FFF;
}
td{
  text-align: center;
  font-weight: normal;
  white-space: pre-wrap;
}
.title{
  font-weight: bold;
  text-align: center;
}
::v-deep .el-dialog__header{
  display: none;
}
.btn{
  margin: auto;
  display: block;
  padding: 10px 57px;
  border-radius: 15px;
}
</style>